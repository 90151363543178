<template>
  <div class="productGalaxy">
    <div class="play-root">
      <video src="../../assets/video/星系.mp4" muted autoplay loop id="dplayer"></video>
      <div class="title">
        <h1>智慧工程、智慧建造</h1>
        <p class="alignLeft">各方现场管理交互联动，过程全要素管理+GIS轻量化渲染引擎，可承载海量数据、实现秒级加载、呈现逼真效果。</p>
        <span><a href="http://galaxy.bjblackhole.com/#/login" target="_blank">登录</a></span>
        <span @click="to('contactus')">合作咨询</span>
      </div>
    </div>
    <div class="galaxy container">
      <h4>智能建造管理平台——星系</h4>
      <p class="alignLeft">星系管理平台基于数据结构化、管理可视化的理念，结合工程建设行业特点，采用“微服务”架构，在业务层面划分了综合监控，全景展现，人员管理，进度管理，质量管理、安全管理、基础数据、文档管理等多个功能模块。平台将分部分项、施工日志、互联物联等多项内容进行了巧妙融合，使得平台的数据在不增加项目管理人员工作量的前提下实现每天实时更新，很好地解决了平台推广中难以解决的“假数据”、“两张皮”等问题。</p>
      <div class="pic"><img src="../../assets/image/galaxy/1.jpg" alt=""></div>
    </div>
    <div class="product">
      <div class="container">
        <h4>产品特色</h4>
        <ul>
          <li>
            <img src="../../assets/image/galaxy/集成.png" alt="">
            <h4>多源集成</h4>
            <p class="alignLeft">平台可集成工程模型，地形模型，实景模型等静态数据，以及工程进度、质量、安全、成本等多维度信息和各类物联网动态数据。</p>
          </li>
          <li>
            <img src="../../assets/image/galaxy/大数据可视化平台.png" alt="">
            <h4>数据可视</h4>
            <p class="alignLeft">平台贯彻“数据可视化”、“管理可视化”的思路，基于自有图形引擎，将三维模型与业务管理流程深度融合，使得传统的工程业务管理更直观、精细、高效。</p>
          </li>
          <li>
            <img src="../../assets/image/galaxy/协同.png" alt="">
            <h4>协同共享</h4>
            <p class="alignLeft">项目各参与方可基于平台进行资料的采集、流转、分析、应用，实现文件级、数据级两级协同，有效地解决“信息孤岛”、“信息海洋”等问题。</p>
          </li>
          <li>
            <img src="../../assets/image/galaxy/知识管理.png" alt="">
            <h4>知识管理</h4>
            <p class="alignLeft">平台支持企业级各类标准库的导入，为项目提供基础数据的支撑；项目级多维数据，能在平台中被总结分析并沉淀到企业标准库中。平台能够帮助持续优化企业各类数据指标，为管理决策提供数据支撑。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="example container">
      <div class="textbox">
        <div class="info">
          <h4>综合监控</h4>
          <p class="alignLeft">反映项目多维度实时进展情况，做到数据实时更新、风险及时预警。综合监控每个分析模块均可灵活配置，每项数据均可实时追溯。为表达地形上的不同业务信息提供了无限可能。</p>
        </div>
        <div class="img"><img src="../../assets/image/galaxy/2.jpg" alt=""></div>
      </div>
      <div class="textbox">
        <div class="img"><img src="../../assets/image/galaxy/3.jpg" alt=""></div>
        <div class="info">
          <h4>全景展现</h4>
          <p class="alignLeft">在项目方案设计阶段，可将无人机采集的项目实景数据导入平台，在平台中结合实测坐标形成规划用地三线信息位置，通过倾斜摄影单体化功能完成设计方案表现等应用。</p>
        </div>
      </div>
      <div class="textbox">
        <div class="info">
          <h4>智能管理</h4>
          <p class="alignLeft">平台提供人员管理、进度管理、质量管理、安全管理、合同管理等业务功能，通过业务数据与构件的联动，结合物联网技术，实现建设工程智慧化。</p>
        </div>
        <div class="img"><img src="../../assets/image/galaxy/4.jpg" alt=""></div>
      </div>
      <div class="textbox">
        <div class="img"><img src="../../assets/image/galaxy/5.jpg" alt=""></div>
        <div class="info">
          <h4>智慧工地</h4>
          <p class="alignLeft">在项目方案设计阶段，可将无人机采集的项目实景数据导入平台，在平台中结合实测坐标形成规划用地三线信息位置，通过倾斜摄影单体化功能完成设计方案表现等应用。</p>
        </div>
      </div>
    </div>
    <More/>
  </div>
</template>

<script>
import More from '@/components/product/more'
export default {
  components: {
    More
  },
  methods: {
    to(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.productGalaxy{
  .play-root {
    width: 100%;
    height: 60vh;
    margin: 0 auto;
    position: relative;
    video{
      width: 100%;
      height:100%;
      object-fit:fill;
    }
    .title{
      position: absolute;
      top: 50%;
      left: 50px;
      transform: translateY(-50%);
      width: 35%;
      // background-color: pink;
      color: #fff;
      h1{
        font-size: 50px;
        font-weight: normal;
        line-height: 80px;
        font-family: "siyuan";
        @media screen and (max-width:768px) {
          font-size: 30px;
          line-height: 40px;
        }
      }
      span{
        width: 100px;
        height: 40px;
        background-color: #0aaff1;
        display: block;
        float: left;
        line-height: 40px;
        margin-top: 20px;
        text-align: center;
        cursor: pointer;
        margin-right: 10px;
        a{
          color: #fff;
        }
      }
    }
  }
  .galaxy{
    text-align: center;
    padding: 80px 0;
    h4{
      font-size: 22px;
    }
    p{
      margin: 20px 0;
    }
    .pic{
      width: 90%;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
  }
  .product{
    background: url('../../assets/image/background/bg (13).jpg');
    padding: 80px 0;
    text-align: center;
    h4{
      font-size: 40px;
      font-weight: 700;
      color: #fff;
    }
    ul{
      overflow: hidden;
      margin-top: 30px;
      li{
        width: calc(25% - 20px);
        background-color: #fff;
        height: 300px;
        padding: 10px 20px;
        float: left;
        margin-right: 25px;
        @media screen and (max-width:768px) {
          width: 80%;
          float: none;
          margin: 30px auto !important;
        }
        @media screen and (min-width:768px) and (max-width:992px) {
          width: 45%;
          margin: 0px 10px 15px 20px;
        }
        h4{
          font-size: 18px;
          line-height: 50px;
          color: #000;
        }
        img{
          width: 50px;
        }
      }
      li:last-child{
        margin-right: 0;
      }
    }
  }
  .example{
    padding: 80px 0;
    .textbox{
      border: 1px solid #000;
      overflow: hidden;
      margin-bottom: 50px;
      @media screen and (max-width:768px) {
        margin: 30px 20px;
      }
      .info{
        width: 40%;
        float: left;
        padding: 20px;
        @media screen and (max-width:768px) {
          padding: 5px;
        }
        h4{
          text-align: center;
          font-size: 24px;
          margin-top: 30px;
          @media screen and (max-width:768px) {
            margin-top: 5px;
          }
        }
        p{
          font-size: 20px;
          text-indent: 2em;
          margin-top: 10px;
          @media screen and (max-width:768px) {
            font-size: 12px;
          }
        }
      }
      .img{
        width: 60%;
        float: left;
        @media screen and (max-width:768px) {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
